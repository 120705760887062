<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="350"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        rounded
        :outlined="buttonOutlined"
        :color="buttonColor"
        :disabled="buttonDisabled"
        :width="buttonWidth"
        class="small-outset ma-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon v-if="buttonIcon">
          {{ buttonIcon }}
        </v-icon>
        {{ buttonText }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text class="pre_line">
        {{ dialogBody }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="green darken-1"
          text
          @click="dialog = false"
        >
          キャンセル
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="ok"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: ''
    },
    buttonColor: {
      type: String,
      default: ''
    },
    buttonOutlined: {
      type: Boolean,
      default: false
    },
    buttonIcon: {
      type: String,
      default: ''
    },
    buttonDisabled: {
      type: Boolean,
      default: false
    },
    buttonWidth: {
      type: String,
      default: undefined
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    dialogBody: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    dialog: false
  }),

  methods: {
    ok () {
      this.$emit('ok-action')
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.pre_line {
  white-space: pre-line;
}
</style>
