
export default {
  data: () => ({
    authRequired: true
  }),

  created () {
    this.$store.commit('updateLoading', { flag: true })
    this.$store.commit('setContentsOnly', { flag: false })
  },

  mounted () {
    if (this.authRequired && this.$store.state.currentUser == null) {
      this.$router.push({ name: 'Login', query: { nextTo: this.$route.fullPath }})
    }
    this.$store.commit('updateLoading', { flag: false })
  }
}
